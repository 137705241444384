import styles from "../styles/pages/Index.module.scss";
import clsx from "clsx";
import map from '../public/images/map.png';
import { useState } from "react";
import DownloadWhite from '../public/images/newSVG/downloadWhite.svg';
import Vector from '../public/images/newSVG/vector.svg';
import Step1 from '../public/images/newSVG/step1.svg';
import Step2 from '../public/images/newSVG/step2.svg';
import Step3 from '../public/images/newSVG/step3.svg';
import Step4 from '../public/images/newSVG/step4.svg';
import Stars from '../public/images/newSVG/stars.svg';
import Arrow from '../public/images/newSVG/arrow.svg';
import { GetStaticProps } from "next";
import { getStatisticsCount } from "../api/local/categories";
import { useReferalLink } from "../hooks";
import { ButtonNew, PageNew, TopCategoriesNew } from "../components";
import { SuggestModalNew } from "../components/SuggestModalNew/SuggestModalNew";
import { ContactManager } from "../components/ContactManager/ContactManager";

const accordionData = [
  {title: 'Как оформить заявку?', content: <>Чтобы оформить заявку, нажмите на кнопку «загрузить спецификацию», выберите нужный вам документ со спецификацией на своем компьютере и загрузите, укажите адрес доставки. <br /><br /> Вы можете составить заявку вручную, не используя готовый документ, просто введите нужные вам товары в поле. <br/> <br/> Если у вас возникают сложности с загрузкой файла или созданием заявки, обратитесь к менеджеру и мы вам поможем.</>},
  {title: 'Можно ли закрыть спецификацию на 100%?', content: 'Мы гарантируем 100% заполнение спецификации, с нами сотрудничают сотни поставщиков и в нашей базе больше 900 000 позиций. На одну позицию в заявке обычно приходится по 2—3 предложения от поставщиков, вы можете выбрать то, которое больше подходит вам.'},
  {title: 'Какие поставщики представлены на маркетплейсе?', content: 'На маркетплейсе более 427 поставщиков из разных регионов России. С нами сотрудничают производители и дистрибьютеры инженерного оборудования.'},
  {title: 'Как происходит доставка?', content: 'Доставка в регионе нашего присутствия осуществляется транспортной компанией-партнером в течение 1-3 рабочих дней после оформления заказа, если в договоре не было оговорено иное. Доставка между регионами также осуществляется транспортной компанией-партнером, сроки доставки зависят от расстояний и габаритов купленных товаров.'},
  {title: 'Как оформить возврат?', content: <>Прием возвращаемого товара от юридических лиц и индивидуальных предпринимателей осуществляется в соответствии с договором поставки и законодательством Российской Федерации и на основании письма о возврате товара, где указана причина возврата товара. <br /><br /> Возврат товара от физических лиц осуществляется в соответствии законом "О защите прав потребителей" от 07.02.1992г. № 2300-1.</>},
];

type HomeProps = {
  totalProductsCount: number,
  totalMerchantsCount: number
}

const Test = ({ totalProductsCount }: HomeProps) => {
  const [selectedAccordion, setSelectedAccordion] = useState<number>();
  const [isSuggestModalOpen, setIsSuggestModalOpen] = useState(false);
  const [isContactManagerModalOpen, setIsContactManagerModalOpen] = useState(false);

  useReferalLink();

  return (
    <PageNew title="Все инженерные системы">
      <main className={styles.main}>
        <section className={styles.section1}>
          <div className={clsx(styles.box, styles.box1)}>
            <h1>Удобный поиск по строительной спецификации</h1>
            <p>Загрузите документы и получите предложение от нескольких поставщиков</p>
            <div className={styles.actions}>
              <ButtonNew variant='filled' type='link' href='/upload-specification'>
                <DownloadWhite />
                Загрузить
              </ButtonNew>
              <ButtonNew onClick={() => setIsContactManagerModalOpen(true)} variant='outlined' type='button'>
                Связаться с менеджером
              </ButtonNew>
            </div>
          </div>
          <div className={clsx(styles.box, styles.box2)}></div>
        </section>
        <section className={styles.section2}>
          <div className={clsx(styles.box, styles.box3)}>
            <div className={styles.info}>
              <div className={styles.infoHead}>
                <Vector />
                <h2>ENSYS</h2>
              </div>
              <p>
                ENSYS.STORE — это онлайн-площадка, объединяющая
                поставщиков и покупателей оборудования
                для инженерных систем в строительстве.
              </p>
            </div>
            <div className={styles.stats}>
              <div className={styles.stat}>
                <h5>{totalProductsCount.toLocaleString('ru-RU')}</h5>
                <p>
                  товарных позиций
                  на маркетплейсе
                </p>
              </div>
              <div className={styles.stat}>
                <h5>67</h5>
                <p>
                  региона работы
                  маркетплейса
                </p>
              </div>
              <div className={styles.stat}>
                <h5>3 года</h5>
                <p>
                  работы
                  на инженерном рынке
                </p>
              </div>
              <div className={styles.stat}>
                <h5>{'>'} 427</h5>
                <p>
                  поставщиков
                  уже работает с нашим
                  маркетплейсом
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.section3}>
          <h2>
            4 ПРОСТЫХ ШАГА, КОТОРЫЕ УСКОРЯТ
            СНАБЖЕНИЕ ВАШИХ ОБЪЕКТОВ
          </h2>
          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepHead}>
                <h3>1</h3>
                <Step1 />
              </div>
              <div className={styles.stepBody}>
                <h3>Загрузите файл</h3>
                <p>
                  Отправьте файл вашему менеджеру или список необходимого оборудования и менеджеры сделают всё за вас.
                </p>
              </div>
              <ButtonNew variant='filled' type='link' href="/upload-specification">
                <DownloadWhite />
                Загрузить
              </ButtonNew>
            </div>
            <div className={styles.step}>
              <div className={styles.stepHead}>
                <h3>2</h3>
                <Step2 />
              </div>
              <div className={styles.stepBody}>
                <h3>Получите предложения</h3>
                <p>
                Поставщики отправят предложения по заявке с актуальными ценами и наличием товаров на склад
                </p>
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepHead}>
                <h3>3</h3>
                <Step3 />
              </div>
              <div className={styles.stepBody}>
                <h3>Сделайте заказ</h3>
                <p>
                  Выберите лучшие предложения по цене
                  и срокам поставки, оплатите заказ
                  и доставку
                </p>
              </div>
            </div>
            <div className={styles.step}>
              <div className={styles.stepHead}>
                <h3>4</h3>
                <Step4 />
              </div>
              <div className={styles.stepBody}>
                <h3>Получите доставку</h3>
                <p>
                  Логистическая служба доставит
                  оборудование до склада или объекта
                  в удобное для вас время
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.destroyMe}>
          <h2>Теперь совершать закупки стало удобно</h2>
        </section>
        <section className={styles.section4}>
          <TopCategoriesNew />
        </section>
        <section className={styles.section5}>
          <div className={styles.box}>
            <div className={styles.map}>
              <h2>450 СКЛАДОВ ПО ВСЕЙ РОССИИ</h2>
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img src={map.src} alt="" />
            </div>
            <div className={styles.careinfo}>
              <h2>ENSYS С ЗАБОТОЙ О ВАС</h2>
              <div className={styles.careRow}>
                <div className={styles.careBlock}>
                  <h3>Персональный менеджер</h3>
                  <p>
                    Работаем с вами персонально по всем
                    этапам — от поиска до доставки
                    и закрывающих документов
                  </p>
                </div>
                <div className={styles.careBlock}>
                  <h3>Безопасность сделок</h3>
                  <p>
                    Мы тщательно проверяем всех поставщиков и несём все риски до получения оборудования на объекте
                  </p>
                </div>
                <div className={styles.careBlock}>
                  <h3>
                    Сравнивайте все цены
                    на рынке
                  </h3>
                  <p>
                    Мы сотрудничаем с множеством поставщиков, чтобы предложить вам лучшие условия и выгодные предложения
                  </p>
                </div>
                <div className={styles.careBlock}>
                  <h3>
                    Закрывайте заявку на 100%
                    в одном окне
                  </h3>
                  <p>
                    Работайте сразу с 360 поставщиков в одном
                    окне, и закрывайте все позиции быстрее
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.section6}>
          <div className={styles.box}>
            <div className={styles.qaHead}>
              <h2>ВОПРОС — ОТВЕТ</h2>
              <Vector />
            </div>
            <div className={styles.qaBody}>
              {accordionData.map((item, i) => (
                <button onClick={() => setSelectedAccordion(i === selectedAccordion ? -1 : i)} style={selectedAccordion === i ? { gap: '16px', boxShadow: '0px 0px 14px 0px #0000001F' } : { gap: '0px' }} key={i} className={styles.accordion}>
                  <section>
                    <p style={{fontSize: '24px'}}>{item.title}</p>
                    <Arrow style={{transform: `rotate(${selectedAccordion === i ? '180deg' : '0deg'})`}} />
                  </section>
                  <div style={selectedAccordion === i ? { gridTemplateRows: '1fr' } : { gridTemplateRows: '0fr' }} className={styles.accordion_item}>
                    <p>{item.content}</p>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </section>
        <section className={styles.section7}>
          <div className={styles.leaveReview2}>
            <h3><span>Отзывы</span> <br /> наших клиентов</h3>
            <p>
              Мы прислушиваемся к Вашим советам и замечаниям. Благодаря вам маркетплейс становится лучше.
            </p>
            <ButtonNew onClick={() => setIsSuggestModalOpen(true)} type='button' variant='outlined'>
              Оставить отзыв
            </ButtonNew>
          </div>
          <div className={styles.reviews}>
            <div className={clsx(styles.box, styles.review1)}>
              <Stars />
              <p>
                Всё оказалось быстро.
                Подготовили документ
                с позициями, сроками и ценами,
                дальше уже согласовывали
                на своей стороне с ген.
                директором. На этапе
                согласования возникли вопросы
                по позициям, но мой менеджер
                Светлана быстро все пояснила
                и предоставила всю необходимую
                информацию от поставщика.
                Результатом доволен
                и продолжаю работать с этим
                сервисом
              </p>
              <div className={styles.reviewer}>
                <p>Игорь Дмитриевич</p>
                <p className={styles.role}>Руководитель снабжения, Москва</p>
              </div>
            </div>
            <div className={clsx(styles.box, styles.review2)}>
              <Stars />
              <p>
                С одним проектом долго не могли найти вентиляционные
                системы по нужным ценам, то цена не устраивала,
                то производитель, то сроки доставки. Когда отправили
                заявку с нами связался менеджер и мы сразу передали
                ей все наши сомнения, на что удалось получить сразу
                несколько предложений от разных поставщиков
                из разных регионов, выбрали, конечно, ближайшего,
                чтобы сократить сроки доставки. С нашей стороны
                согласовать получилось быстро сделку, а на стороне
                поставщика возникли вопросы, но менеджер нам с этим
                тоже помог и получилось оперативно провести сделку.
                Сервис устраивает.
              </p>
              <div className={styles.reviewer}>
                <p>Анатолий</p>
                <p className={styles.role}>Ген. директор, Москва</p>
              </div>
            </div>
            <div className={styles.leaveReview}>
              <h3><span>Отзывы</span> <br /> наших клиентов</h3>
              <p>
                Мы прислушиваемся к Вашим советам и замечаниям. Благодаря вам маркетплейс становится лучше.
              </p>
              <ButtonNew onClick={() => setIsSuggestModalOpen(true)} type='button' variant='outlined'>
                Оставить отзыв
              </ButtonNew>
            </div>
          </div>
        </section>
        <section className={styles.section8}>
          <div className={clsx(styles.box, styles.box2)}></div>
          <div className={clsx(styles.box, styles.box1)}>
            <h2>Нужно оборудование по лучшим ценам?</h2>
            <p>Загрузите инженерную спецификацию или список оборудования,
            чтобы получить предложения от нескольких поставщиков</p>
            <div className={styles.actions}>
              <ButtonNew type='link' href='/upload-specification' variant='filled'>
                <DownloadWhite />
                Загрузить
              </ButtonNew>
              <ButtonNew onClick={() => setIsContactManagerModalOpen(true)} type='button' variant='outlined'>
                Связаться с менеджером
              </ButtonNew>
            </div>
          </div>
        </section>
      </main>
      <SuggestModalNew isModalOpen={isSuggestModalOpen} setIsModalOpen={() => setIsSuggestModalOpen(false)} />
      <ContactManager isModalOpen={isContactManagerModalOpen} setIsModalOpen={() => setIsContactManagerModalOpen(false)} />
    </PageNew>
  );
};

export default Test;

export const getStaticProps: GetStaticProps = async () => {
  const statistics = (await getStatisticsCount()).statistics[0];
  return {
    props: {
      totalProductsCount: statistics.data.products,
      totalMerchantsCount: statistics.data.merchants
    },
    revalidate: 24 * 60 * 60 // 24 часа
  };
};