import styles from './ContactManager.module.scss';
import * as Yup from 'yup';
import { useSelector } from "../../store/hooks";
import { getToken } from '../../store/loginSlice';
import { SuggestOption } from '../SuggestModalNew/SuggestModalNew';
import { useFormik } from 'formik';
import { ModalNew } from '../ModalNew/ModalNew';
import ErrorAlert from '../ErrorAlert/ErrorAlert';
import { FormikTextFieldNew } from '../inputs/FormikTextFieldNew/FormikTextFieldNew';
import { ButtonNew } from '../ButtonNew/ButtonNew';
import SuggestSelect from '../SuggestModalNew/components/SuggestSelect';
import { FormFieldNew } from '../inputs/FormFieldNew/FormFieldNew';
import { useMutation } from '@apollo/client';
import { CONTACT_MANAGER } from '../../api/public/suggestion';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: () => void;
}

const REQUIRED = 'Обязательное поле';


const checkExtraFields = (options: SuggestOption['options'] | undefined, pos: number, ctx: Yup.TestContext) => {
  if (!options) return true;
  const invalidOptionIndex = options[pos];
  if (invalidOptionIndex && !invalidOptionIndex.extra) {
    return ctx.createError({
      path: `options.${invalidOptionIndex.value}.extra`,
      params: {label: invalidOptionIndex},
      message: 'Обязательное поле',
    });
  }
  return true;
};


const suggestionQuestions: SuggestOption[] = [
  {
    label: 'Как с вами удобнее связаться?',
    name: 'contact',
    options: [{value: 'По телефону', extra: 'Номер телефона'}, {value: 'По WhatsApp', extra: 'Номер телефона'}, {value: 'По почте', extra: 'Email'}],
    schema: Yup.object().shape({
      label: Yup.string(),
      value: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required(REQUIRED),
          extra: Yup.string().required(REQUIRED)
        }),
      ),
    })
    .test('contact', 'Обязательное поле', (value) => value.value?.length ? true : false)
    .test('options1', '', (options, ctx) => checkExtraFields(options.value, 0, ctx))
    .test('options2', '', (options, ctx) => checkExtraFields(options.value, 1, ctx))
    .test('options3', '', (options, ctx) => checkExtraFields(options.value, 2, ctx)),
    withInput: false,
    type: 'checkbox'
  }
];

export const ContactManager = ({isModalOpen, setIsModalOpen}: Props) => {
  const isLoggedIn = useSelector(getToken);

  const questionsSchema = Object.fromEntries(suggestionQuestions.map((field) => [field.name, field.schema]));
  const questionsInitialValues = Object.fromEntries(suggestionQuestions.map((field)=>[field.name, {label: field.label, value: []}]));

  const validationSchema = Yup.object().shape({
    companyName: isLoggedIn ? Yup.string() : Yup.string().required(REQUIRED),
    name: isLoggedIn ? Yup.string() : Yup.string().required(REQUIRED),
    ...questionsSchema
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      name: '',
      ...questionsInitialValues
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const { name, companyName, ...rest } = values;

      contactManager({
        variables: {
          suggestion: {
            name,
            companyName,
            options: Object.values(rest).filter((q: any) => q.value.length > 0) as [{label: string, value: SuggestOption['options'], other?: string}]
          }
        }
      });
      actions.resetForm();
    }
  });

  const [contactManager, { error, called, reset }] = useMutation(CONTACT_MANAGER);


  return (
    <ModalNew title={!called ? 'Связаться с менеджером' : ''} show={isModalOpen} onClose={() => {setIsModalOpen(), reset()}}>
      {!called 
      ? <form onSubmit={formik.handleSubmit}>
          <div className={styles.container}>
            <ErrorAlert error={error} />
            <div className={styles.form}>
              {!isLoggedIn && 
                <>
                  <div>
                    <FormikTextFieldNew formik={formik} field="companyName" label="Название компании" required />
                  </div>
                  <div>
                    <FormikTextFieldNew formik={formik} field="name" label="Ваши ФИО" required />
                  </div>
                </>
              }
              {suggestionQuestions.map((q) => (
                <FormFieldNew 
                  key={q.name} 
                  error={
                    typeof formik.errors[q.name as keyof typeof formik.errors] === 'object' 
                      ? '' 
                      : formik.errors[q.name as keyof typeof formik.errors]
                  } 
                  label={q.label} 
                  required
                >
                  <SuggestSelect
                    field={q.name as keyof typeof formik.values}
                    formik={formik} 
                    {...q}
                  />
                </FormFieldNew>
              ))}
            </div>
            <ButtonNew style={{alignSelf: 'end'}} variant='filled' onClick={formik.handleSubmit}>
              Отправить
            </ButtonNew>
          </div>
        </form>
      : 
        <div style={{display: 'flex', flexDirection: 'column', gap: 50, alignItems: 'center'}}>
          <h2>Мы скоро свяжемся с вами!</h2>
          <ButtonNew variant='filled' onClick={() => {setIsModalOpen(), reset()}}>
            Закрыть
          </ButtonNew>
        </div>
      }
    </ModalNew>
  );
};